import { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { HighlightOff } from "@material-ui/icons";
import logo from "src/images/logo.svg";

import {
  Box,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Slide,
} from "@material-ui/core";

import { navigateToApp } from "src/redirect_to_app";

import { Link } from "gatsby-theme-material-ui";
import LoginModal from "../login";
import FMPModal from "../fmp";
import { userStatusQuery } from "src/actions/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: "#0000008c",
      [theme.breakpoints.down("sm")]: {
        "& .toolbar": {
          background: (props: any) =>
            props.drawerState ? theme.palette["dark_blue"].default : "inherit",
        },
      },
      "& a.MuiLink-root": {
        color: theme.palette.secondary.main,
      },
      "& a.MuiLink-root.active": {
        color: theme.palette.primary.main,
      },
    },

    menuButton: {
      marginLeft: theme.spacing(2),
      color: "black",
    },
    logo: {
      maxHeight: "36px",
      maxWidth: "140px",
      marginTop: "auto",
    },
    authButtons: {
      marginLeft: theme.spacing(2),
    },
    listItemCenter: {
      justifyContent: "center",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      marginTop: -42,
      background: theme.palette["dark_blue"].default,
      color: "white",
      height: (props) => (props.drawerState ? "auto" : 0),
      transition:
        "transform 255ms cubic-bezier(0, 0, 0.2, 1) 0ms, height 255ms!important",
      "& .dot": {
        position: "relative",
        width: 42,
        height: 42,
        left: -21,
        borderRadius: 24,
        top: 96,
        background: "#fdf5ec66",
      },
      "& .list div span": {
        fontWeight: 500,
        fontSize: "2em",
      },
    },
  })
);

export default function TopBar() {
  const [login_open, setLoginOpen] = useState(false);
  const [fmp_open, setFMPOpen] = useState(false);
  const [drawerState, setDrawerState] = useState(false);

  const { data, refetch: refresh_status } = userStatusQuery();

  // const user = !error || !data.success ? null : data.user;
  const user = data?.success && data?.user ? data.user : null;

  const classes = useStyles({ drawerState });

  const toggleDrawer = () => setDrawerState((v) => !v);
  const showLogin = () => setLoginOpen(true);
  const showFMP = () => setFMPOpen(true);

  const logout = async () => {
    await fetch("/api/auth/logout.json");
    return refresh_status();
  };

  return (
    // <div className={classes.root}>
    <AppBar
      position="static"
      color="transparent"
      className={classes.root}
      elevation={0}
    >
      <LoginModal
        open={login_open}
        show_fmp={showFMP}
        onClose={() => setLoginOpen(false)}
        refresh_status={refresh_status}
      />
      <FMPModal open={fmp_open} onClose={() => setFMPOpen(false)} />
      <Toolbar className="toolbar">
        <div style={{ flexGrow: 1, textAlign: "center" }}>
          <a href="/">
            <img className={classes.logo} src={logo} alt="Fieldscope" />
          </a>
        </div>
        <Hidden implementation="css" smDown>
          <Button component={Link} href="/#how_it_works" color="inherit">
            How it works
          </Button>
          <Button href="/signup/provider/" color="inherit">
            Become a Provider
          </Button>
          {user && (
            <>
              <Button
                onClick={navigateToApp}
                variant="contained"
                color="primary"
                className={classes.authButtons}
              >
                Dashboard
              </Button>
              <Button
                onClick={logout}
                variant="text"
                // color="secondary"
                className={classes.authButtons}
              >
                Logout
              </Button>
            </>
          )}
          {!user && (
            <>
              <Button
                variant="contained"
                color="primary"
                className={classes.authButtons}
                href="/signup/"
              >
                Signup
              </Button>
              <Button
                onClick={showLogin}
                variant="outlined"
                color="primary"
                className={classes.authButtons}
              >
                Login
              </Button>
            </>
          )}
        </Hidden>
        <Hidden implementation="css" mdUp>
          <IconButton
            onClick={toggleDrawer}
            edge="start"
            className={classes.menuButton}
            aria-label="menu"
          >
            {drawerState ? (
              <HighlightOff htmlColor="white" fontSize="large" />
            ) : (
              <MenuIcon fontSize="large" />
            )}
          </IconButton>
        </Hidden>
      </Toolbar>
      <Slide direction="down" in={drawerState}>
        <Box className={classes.drawer}>
          <div className="dot" />
          <List className="list">
            {user && (
              <>
                <ListItem button component="a" onClick={navigateToApp}>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component="a" onClick={logout}>
                  <ListItemText primary="Logout" />
                </ListItem>
              </>
            )}
            {!user && (
              <>
                <ListItem button component="a" href="/signup/">
                  <ListItemText primary="Sign Up" />
                </ListItem>
                <ListItem button component="a" onClick={showLogin}>
                  <ListItemText primary="Login" />
                </ListItem>
              </>
            )}
            <ListItem button component="a" href="/#how_it_works">
              <ListItemText primary="How it works" />
            </ListItem>
            <ListItem button component="a" href="/signup/provider/">
              <ListItemText primary="Become a Provider" />
            </ListItem>
          </List>
        </Box>
      </Slide>
    </AppBar>
    // </div>
  );
}
